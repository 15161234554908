// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Utils
import AcUUID from '@utils/ac-uuid';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcAttribute from '@atoms/ac-attribute/ac-attribute.web';

const _CLASSES = {
  MAIN: 'ac-attributes',
};

const AcAttributes = ({ columns = 2, attributes }) => {
  const createChunks = () => {
    let index = 0;
    let total = attributes.length;
    let chunksize = Math.ceil(total / columns);
    var result = [];

    for (index = 0; index < total; index += chunksize) {
      let chunk = attributes.slice(index, index + chunksize);
      result.push(chunk);
    }

    return result;
  };

  const renderAttributes = () => {
    const chunks = createChunks();

    return (
      <AcRow noGutters={true}>
        {chunks &&
          chunks.map((chunk, index) => (
            <AcColumn
              xxs={12}
              xs={12}
              sm={6}
              md={index % 2 === 0 ? '4' : '8'}
              key={AcUUID()}
            >
              {chunk &&
                chunk.map(item => (
                  <AcAttribute
                    attribute={item}
                    key={`ac-attribute-${item.id}`}
                  />
                ))}
            </AcColumn>
          ))}
      </AcRow>
    );
  };

  const getStyleClassNames = () => {
    return clsx(_CLASSES.MAIN);
  };

  return <div className={getStyleClassNames()}>{renderAttributes()}</div>;
};

export default React.memo(AcAttributes);
