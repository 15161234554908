// Imports => React
import React, { useCallback } from 'react';
import clsx from 'clsx';

/* eslint react-app/import/no-webpack-loader-syntax: off */
import AcLoaderVisual from '-!svg-react-loader!@assets/images/realiance-brand-name-2x.svg';

const _CLASSES = {
  MAIN: 'ac-loader',
  WRP: {
    MAIN: 'ac-loader-wrp',
    COVER: 'ac-loader-wrp--cover',
    DEFAULT: 'ac-loader-wrp--pitch',
    WHITE: 'ac-loader-wrp--white',
    LIGHT: 'ac-loader-wrp--light',
    OFFWHITE: 'ac-loader-wrp--offwhite',
    SUBTLE: 'ac-loader-wrp--subtle',
    MEDIUM: 'ac-loader-wrp--medium',
    DARK: 'ac-loader-wrp--dark',
    PITCH: 'ac-loader-wrp--pitch',
    ALPHA: 'ac-loader-wrp--alpha',
  },
  DEFAULT: 'ac-loader--pitch',
  WHITE: 'ac-loader--white',
  LIGHT: 'ac-loader--light',
  OFFWHITE: 'ac-loader--offwhite',
  SUBTLE: 'ac-loader--subtle',
  MEDIUM: 'ac-loader--medium',
  DARK: 'ac-loader--dark',
  PITCH: 'ac-loader--pitch',
  ALPHA: 'ac-loader--alpha',
};

// Component
const AcLoader = ({
  theme = 'default',
  className,
  coverTheme = 'white',
  cover = false,
  wrpClassName,
  loading = false,
}) => {
  const getStyleClassNames = useCallback(() => {
    return clsx([
      _CLASSES.MAIN,
      theme && _CLASSES[theme.toUpperCase()],
      className,
    ]);
  }, []);

  const getWrpClassNames = useCallback(() => {
    return clsx([
      _CLASSES.WRP.MAIN,
      cover && _CLASSES.WRP.COVER,
      coverTheme && _CLASSES.WRP[coverTheme.toUpperCase()],
      wrpClassName,
    ]);
  }, []);

  return loading ? (
    <div className={getWrpClassNames()}>
      <div className={getStyleClassNames()}>
        <AcLoaderVisual />
      </div>
    </div>
  ) : null;
};

export default React.memo(AcLoader);
