// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';

// Imports => Controller
import AcLogoController from './ac-logo.controller';

// Component
@inject('routing')
@observer
class AcLogo extends AcLogoController {
  render() {
    const { Visual } = this.state;

    return (
      <div
        onClick={event => this.handleClickEvent(event)}
        className={this.getStyleClassNames()}
      >
        <div className={this.getContainerClassNames()}>
          <Visual />
        </div>
      </div>
    );
  }
}

export default AcLogo;
