// Imports => React
import React from 'react';

const AcGoogleMapsLink = ({ url, linkText }) => {
  return (
    <a
      className={'ac-google-maps-link'}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>{linkText}</span>
    </a>
  );
};

export default AcGoogleMapsLink;
