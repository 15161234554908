// Imports => React
import React, { useCallback } from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-fog',
  LAYER: 'ac-fog__layer',
  IMAGE: 'ac-fog__image',
};

const AcFog = ({ className }) => {
  const getImageClassNames = useCallback(num => {
    return clsx(_CLASSES.IMAGE, num && `${_CLASSES.IMAGE}--${num}`);
  }, []);

  const getLayerClassNames = useCallback(num => {
    return clsx(_CLASSES.LAYER, num && `${_CLASSES.LAYER}--${num}`);
  }, []);

  const getStyleClassNames = useCallback(() => {
    return clsx(_CLASSES.MAIN, className);
  }, []);

  return (
    <div className={getStyleClassNames()}>
      <div className={getLayerClassNames(1)}>
        <div className={getImageClassNames(1)} />
        <div className={getImageClassNames(1)} />
      </div>
      <div className={getLayerClassNames(2)}>
        <div className={getImageClassNames(2)} />
        <div className={getImageClassNames(2)} />
      </div>
      <div className={getLayerClassNames(2)}>
        <div className={getImageClassNames(2)} />
        <div className={getImageClassNames(2)} />
      </div>
    </div>
  );
};

export default React.memo(AcFog);
