// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-container',
  FLUID: 'ac-container--fluid',
};

// Component
const AcGridContainer = ({ className, fluid, children }) => {
  const getStyleClassNames = () => {
    return clsx(!fluid && _CLASSES.MAIN, fluid && _CLASSES.FLUID, className);
  };
  return <div className={getStyleClassNames()}>{children}</div>;
};

export default React.memo(AcGridContainer);
