// Imports => React
import React, { useCallback } from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-card',
  DEFAULT: 'ac-card--default',
  TRANSPARENT: 'ac-card--transparent',
  WHITE: 'ac-card--white',
  LIGHT: 'ac-card--light',
  SUBTLE: 'ac-card--subtle',
  DARK: 'ac-card--dark',
  PITCH: 'ac-card--pitch',
  ALPHA: 'ac-card--alpha',
  OMEGA: 'ac-card--omega',
  ROUNDED: 'ac-card--rounded',
  NO_SHADOW: 'ac-card--no-shadow',
  NO_ANIMATION: 'ac-card--no-animation',
  ALIGNMENT: {
    LEFT: 'ac-card--align-left',
    CENTER: 'ac-card--align-center',
    RIGHT: 'ac-card--align-right',
  },
};

// Component
const AcCard = ({
  id,
  children,
  callback,
  theme = 'default',
  borderRadius = false,
  hoverAnimation = true,
  alignment,
  shadow = true,
  className,
  width,
  height,
}) => {
  const handleCallback = useCallback(event => {
    if (callback) callback(event);
  }, []);

  const getStyleClassNames = useCallback(() => {
    return clsx(
      _CLASSES.MAIN,
      theme && _CLASSES[theme.toUpperCase()],
      borderRadius && _CLASSES.ROUNDED,
      !hoverAnimation && _CLASSES.NO_ANIMATION,
      alignment && _CLASSES.ALIGNMENT[alignment.toUpperCase()],
      !shadow && _CLASSES.NO_SHADOW,
      className
    );
  }, [className]);

  const getInlineStyle = useCallback(() => {
    return {
      width: width ? `${width / 10}rem` : undefined,
      height: height ? `${height / 10}rem` : undefined,
    };
  }, []);

  return (
    <section
      id={id}
      className={getStyleClassNames()}
      style={getInlineStyle()}
      onClick={handleCallback}
    >
      {children}
    </section>
  );
};

export default React.memo(AcCard);
