// Imports => React
import { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import AcUUID from '@utils/ac-uuid';

const _CLASSES = {
  MAIN: 'ac-radiobutton',
  DISABLED: 'ac-radiobutton--disabled',
  BEFORE: 'ac-radiobutton--before',
  AFTER: 'ac-radiobutton--after',
  INPUT: {
    MAIN: 'ac-radiobutton__input',
    DISABLED: 'ac-radiobutton__input--disabled',
  },
  DISPLAY: {
    MAIN: 'ac-radiobutton__display',
    DISABLED: 'ac-radiobutton__display--disabled',
  },
  LABEL: {
    MAIN: 'ac-radiobutton__label',
    DISABLED: 'ac-radiobutton__label--disabled',
    BEFORE: 'ac-radiobutton__label--before',
    AFTER: 'ac-radiobutton__label--after',
  },
};

// Controller
class AcRadiobuttonController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
      ref: AcUUID(),
      display: false,
    };

    this.onChangeEvent = this.onChangeEvent.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.checked === prevState.checked) return prevState;

    return {
      ...prevState,
      checked: nextProps.checked,
    };
  }

  onChangeEvent(event) {
    const { checked } = this.state;
    const { name, value, callback } = this.props;

    const selected = !checked;

    this.setState({ checked: selected }, () => {
      if (callback && selected) callback(event, name, value);
    });
  }

  isChecked() {
    return this.state.checked;
  }

  getStyleClassNames() {
    const { disabled, position } = this.props;

    return clsx(
      _CLASSES.MAIN,
      disabled && _CLASSES.DISABLED,
      position && _CLASSES[position.toUpperCase()]
    );
  }

  getLabelClassNames() {
    const { disabled, position } = this.props;

    return clsx(
      _CLASSES.LABEL.MAIN,
      disabled && _CLASSES.LABEL.DISABLED,
      position && _CLASSES.LABEL[position.toUpperCase()]
    );
  }

  getInputClassNames() {
    const { disabled } = this.props;

    return clsx(_CLASSES.INPUT.MAIN, disabled && _CLASSES.INPUT.DISABLED);
  }

  getDisplayClassNames() {
    const { disabled } = this.props;

    return clsx(_CLASSES.DISPLAY.MAIN, disabled && _CLASSES.DISPLAY.DISABLED);
  }
}

AcRadiobuttonController.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

AcRadiobuttonController.defaultProps = {
  children: 'I am a radiobutton',
  checked: false,
  disabled: false,
  position: 'after',
};

export default AcRadiobuttonController;
