// Imports => React
import React from 'react';

// Imports => Controller
import AcAttachmentController from './ac-attachment.controller.js';

class AcAttachment extends AcAttachmentController {
  render() {
    const { mime_type, url, name, title, clickHandler } = this.props;

    return mime_type ? (
      <div
        className={this.getStyleClassNames()}
        onClick={clickHandler || this.handleClick}
      >
        <i className={this.getIconClassNames(mime_type)} />
        <div className={this.getLabelClassNames()}>{name}</div>
      </div>
    ) : (
      <div className={this.getStyleClassNames()} onClick={this.handleClick}>
        <i className={this.getIconClassNames('video')} />
        <div className={this.getLabelClassNames()}>{title ? title : url}</div>
      </div>
    );
  }
}

export default AcAttachment;
