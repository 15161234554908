// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-heading',
  H1: 'ac-heading--h1',
  H2: 'ac-heading--h2',
  H3: 'ac-heading--h3',
  H4: 'ac-heading--h4',
  H5: 'ac-heading--h5',
  H6: 'ac-heading--h6',
  H7: 'ac-heading--h7',
  DEFAULT: 'ac-heading--default',
  WHITE: 'ac-heading--white',
  LIGHT: 'ac-heading--light',
  SUBTLE: 'ac-heading--subtle',
  MEDIUM: 'ac-heading--medium',
  DARK: 'ac-heading--dark',
  PITCH: 'ac-heading--pitch',
  ALPHA: 'ac-heading--alpha',
  OMEGA: 'ac-heading--omega',
  UPPERCASE: 'ac-heading--uppercase',
};

// Component
const AcHeading = ({
  rank = 2,
  theme = 'default',
  type,
  variant,
  children,
  t,
  className,
}) => {
  const getTag = () => {
    return rank && rank >= 7 ? 'p' : rank > 6 ? 'h6' : `h${rank}`;
  };

  const getStyleClassNames = () => {
    return clsx(
      _CLASSES.MAIN,
      rank && _CLASSES[`H${rank}`],
      theme && _CLASSES[theme.toUpperCase()],
      variant && _CLASSES[variant.toUpperCase()],
      className
    );
  };

  const Tag = getTag(rank, type);

  return (
    <Tag
      className={getStyleClassNames(rank, type)}
      dangerouslySetInnerHTML={{ __html: t(children) }}
    />
  );
};

export default withNamespaces()(React.memo(AcHeading));
