// Imports => React
import React from 'react';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

// Imports => Controller
import AcRadiobuttonController from './ac-radiobutton.controller';

// Component
class AcRadiobutton extends AcRadiobuttonController {
  render() {
    const { children, reference, name, value, position, disabled } = this.props;
    const { ref } = this.state;

    return (
      <label
        htmlFor={reference ? reference : ref}
        ref={reference ? reference : ref}
        className={this.getStyleClassNames()}
      >
        <input
          type={'radio'}
          id={reference ? reference : ref}
          name={name}
          value={value}
          disabled={disabled}
          onChange={this.onChangeEvent}
          defaultChecked={this.isChecked()}
          className={this.getInputClassNames()}
        />
        <span className={this.getLabelClassNames()}>
          {position === 'before' && children}
          <span
            className={this.getDisplayClassNames()}
            role={'button'}
            ref={node => (this.state.display = node)}
            data-active={this.isChecked()}
          >
            {!disabled && (
              <AcRipple
                theme={'alpha'}
                fixed={true}
                target={this.state.display}
              />
            )}
          </span>
          {position === 'after' && children}
        </span>
      </label>
    );
  }
}

export default AcRadiobutton;
