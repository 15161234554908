// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-attachment',
  LABEL: 'ac-attachment__label',
  ICON: {
    MAIN: 'ac-icon ac-attachment__icon',
    DOC: 'ac-icon--file-word-box',
    VIDEO: 'ac-icon--play-circle',
    DOCX: 'ac-icon--file-word-box',
    PDF: 'ac-icon--file-pdf-alt',
    POWERPOINT: 'ac-icon--file-powerpoint-box',
    XLS: 'ac-icon--file-excel-box',
  },
};

class AcAttachmentController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    if (event) event.stopPropagation();

    const { url, name } = this.props;

    if (url) window.open(url, name);
  }

  getLabelClassNames() {
    return clsx(_CLASSES.LABEL);
  }

  getIconClassNames(type) {
    let file_type = false;
    switch (type) {
      case 'video':
        file_type = 'video';
        break;
      case 'application/pdf':
        file_type = 'pdf';
        break;

      case 'application/xls':
        file_type = 'xls';
        break;

      case 'application/doc':
      case 'application/docx':
        file_type = 'docx';
        break;

      case 'application/ppt':
        file_type = 'powerpoint';
        break;

      default:
        file_type = false;
    }

    return clsx(
      _CLASSES.ICON.MAIN,
      file_type && _CLASSES.ICON[file_type.toUpperCase()]
    );
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcAttachmentController.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
};

AcAttachmentController.defaultProps = {};

export default AcAttachmentController;
