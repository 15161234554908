// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcStepController from './ac-step.controller';
import PropTypes from 'prop-types';

// Component
class AcStep extends AcStepController {
  render() {
    const { circleSize, circleViewBox, circleRadius } = this.state;

    const {
      divider,
      title,
      subtitle,
      comment,
      number,
      indexes,
      finished,
      horizontal,
      t,
    } = this.props;

    return (
      <>
        <div className={this.getStyleClassNames()}>
          <div className={this.getContentClassNames()}>
            {title && (
              <span className={this.getTitleClassNames()}>{t(title)}</span>
            )}
            <div
              className={this.getSVGClassNames()}
              style={this.getSVGInlineStyles()}
              onClick={event => this.onStepClickEvent(event)}
            >
              {this.renderDivider() && (
                <div className={this.getDividerClassNames()} style={divider} />
              )}
              <svg
                className={this.getSVGCircleClassNames()}
                viewBox={circleViewBox}
                width={circleSize}
                height={circleSize}
              >
                <circle
                  className={this.getSVGCircleBackClassNames()}
                  cx={circleRadius + 1}
                  cy={circleRadius + 1}
                  r={circleRadius}
                />
                <circle
                  className={this.getSVGCircleFillClassNames()}
                  cx={circleRadius + 1}
                  cy={circleRadius + 1}
                  r={circleRadius}
                />
                <circle
                  className={this.getSVGCircleFrontClassNames()}
                  cx={circleRadius + 1}
                  cy={circleRadius + 1}
                  r={circleRadius}
                  strokeDasharray={this.getDasharrayValue()}
                />
              </svg>
              {indexes && !finished && (
                <span className={this.getSVGContentClassNames()}>{number}</span>
              )}
              {finished && (
                <span className={this.getSVGContentClassNames()}>
                  <i className={this.getIconClassNames()} />
                </span>
              )}
              {comment && (
                <>
                  <span className={'ac-step__tooltip'}>
                    <span className={'ac-step__tooltip__body'}>
                      {t(comment)}
                    </span>
                  </span>
                  <span className={'ac-step__tooltip-pointer'}>
                    <i
                      className={
                        'ac-icon ac-icon--comment-processing-outline ac-step__tooltip-pointer__icon'
                      }
                    />
                  </span>
                </>
              )}
            </div>
            {!horizontal && subtitle && (
              <span className={this.getSubTitleClassNames()}>
                {t(subtitle)}
              </span>
            )}
          </div>
        </div>
      </>
    );
  }
}

AcStepController.propTypes = {
  ...AcStepController.propTypes,
  circleSize: PropTypes.number,
};

AcStepController.defaultProps = {
  ...AcStepController.defaultProps,
  circleSize: 41,
};

export default withNamespaces()(AcStep);
