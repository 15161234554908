// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-language-selector',
  LOADING: 'ac-language-selector--loading',
  DEFAULT: 'ac-language-selector--default',
  PITCH: 'ac-language-selector--pitch',
  SELECTED: 'ac-language-selector__selected',
  OPEN_MENU: 'ac-language-selector--open',
  LANGUAGES: {
    MAIN: 'ac-language-selector__languages-list',
    ITEM: 'ac-language-selector__languages-list__item',
  },
};

// Controller
class AcLanguageSelectorController extends React.Component {
  constructor(props) {
    super(props);

    this.wrp = null;

    this.store = props.store;

    this.state = {
      currentLanguage: props.currentLanguage,
      openLanguageList: false,
    };

    this.handleClickEvent = this.handleClickEvent.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickEvent, false);
  }

  componentWillUnMount() {
    document.removeEventListener('click', this.handleClickEvent, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.currentLanguage === nextProps.currentLanguage)
      return prevState;

    return {
      ...prevState,
      currentLanguage: nextProps.currentLanguage,
    };
  }

  handleClickEvent(event) {
    if (!event) return;
    if (!this.wrp || this.wrp === null) return;
    if (this.wrp.contains(event.target)) return;

    this.toggleList(false);
  }

  toggleList(state) {
    const { openLanguageList } = this.state;

    this.setState({
      openLanguageList: typeof state === 'boolean' ? state : !openLanguageList,
    });
  }

  parseLanguagesList() {
    const { currentLanguage } = this.state,
      { languages } = this.props,
      languagesList = languages.slice(0).filter(function(value) {
        return value !== currentLanguage;
      });

    return languagesList;
  }

  setLanguage(event, language) {
    event.preventDefault();

    this.toggleList(false);

    const { callback } = this.props;
    if (callback) callback(language);
  }

  getStyleClassNames() {
    const { openLanguageList } = this.state;
    const { theme, className } = this.props;
    const { loading } = this.store.settings;

    return clsx(
      _CLASSES.MAIN,
      openLanguageList && _CLASSES.OPEN_MENU,
      theme && _CLASSES[theme.toUpperCase()],
      loading && _CLASSES.LOADING,
      className
    );
  }

  getSelectedLanguageClassNames() {
    return clsx(_CLASSES.SELECTED);
  }

  getLanguagesListItemClassNames() {
    return clsx(_CLASSES.LANGUAGES.ITEM);
  }

  getLanguagesListClassNames() {
    return clsx(_CLASSES.LANGUAGES.MAIN);
  }

  getInlineStyle() {
    const { styles } = this.props;

    return {
      ...styles,
    };
  }
}

AcLanguageSelectorController.propTypes = {
  callback: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  languages: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired,
  ]),
  theme: PropTypes.oneOf(['default', 'transparent', 'pitch']),
  className: PropTypes.string,
  styles: PropTypes.object,
};

AcLanguageSelectorController.defaultProps = {
  styles: {},
  theme: 'default',
};

export default AcLanguageSelectorController;
