// Imports => React
import React, { useCallback } from 'react';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-rich-content',
};

// Component
const AcRichContent = ({ options, content, t, className }) => {
  const getContentOptions = useCallback(() => {
    return options ? options : false;
  }, []);

  const getStyleClassNames = useCallback(() => {
    return clsx(_CLASSES.MAIN, className && className);
  }, []);

  return (
    <div
      className={getStyleClassNames()}
      dangerouslySetInnerHTML={{
        __html: t(content, getContentOptions()),
      }}
    />
  );
};

export default withNamespaces()(React.memo(AcRichContent));
