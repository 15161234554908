// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Utils
import AcUUID from '@utils/ac-uuid';

const _CLASSES = {
  MAIN: 'ac-section',
  FULLHEIGHT: 'ac-section--fullheight',
  DENSE: 'ac-section--dense',
  DEFAULT: 'ac-section--default',
  WHITE: 'ac-section--white',
  LIGHT: 'ac-section--light',
  SUBTLE: 'ac-section--subtle',
  MEDIUM: 'ac-section--medium',
  DARK: 'ac-section--dark',
  PITCH: 'ac-section--pitch',
  ALPHA: 'ac-section--alpha',
  OMEGA: 'ac-section--omega',
};

// Component
const AcSection = ({
  children,
  id = AcUUID(),
  theme = 'default',
  dense = false,
  fullheight = false,
  className,
}) => {
  const getStyleClassNames = () => {
    return clsx(
      _CLASSES.MAIN,
      theme && _CLASSES[theme.toUpperCase()],
      dense && _CLASSES.DENSE,
      fullheight && _CLASSES.FULLHEIGHT,
      className
    );
  };
  return (
    <section className={getStyleClassNames()} id={id}>
      {children}
    </section>
  );
};

export default React.memo(AcSection);
