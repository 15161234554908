// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';

import ErrorIcon from '@assets/images/error-icon.svg';

const _CLASSES = {
  MAIN: 'ac-password-notifications',
  CONTENT: 'ac-password-notifications-content',
  BODY: 'ac-password-notifications-content__body',
  ICON: 'ac-password-notifications-content-icon',
};

const AcPasswordNotifications = ({ title, description, variant }) => {
  const getMainClassNames = () => {
    return clsx(_CLASSES.MAIN);
  };

  const getContentClassNames = () => {
    return clsx(_CLASSES.CONTENT);
  };

  const getContentBodyClassNames = () => {
    return clsx(_CLASSES.BODY);
  };

  const getContentIconClassNames = () => {
    return clsx(_CLASSES.ICON);
  };

  return (
    variant !== 'success' && (
      <div className={getMainClassNames()}>
        <div className={getContentClassNames()}>
          <span className={getContentIconClassNames()}>
            <ErrorIcon />
          </span>
          <div className={getContentBodyClassNames()}>
            <div>{title}</div>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default withNamespaces()(React.memo(AcPasswordNotifications));
