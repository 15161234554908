// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-step',
  HORIZONTAL: 'ac-step--horizontal',
  CURRENT: 'ac-step--current',
  FINISHED: 'ac-step--finished',
  CONTENT: 'ac-step__content',
  SIZES: {
    DEFAULT: 'ac-step--default',
    MEDIUM: 'ac-step--medium',
    SMALL: 'ac-step--small',
  },
  TITLE: {
    MAIN: 'ac-step__title',
    SUB: 'ac-step__title--sub',
  },
  SVG: {
    MAIN: 'ac-step__icon',
    CIRCLE: {
      MAIN: 'ac-step__icon__circle',
      FRONT: 'ac-step__icon__circle--front',
      FILL: 'ac-step__icon__circle--fill',
      BACK: 'ac-step__icon__circle--back',
    },
    CONTENT: {
      MAIN: 'ac-step__icon__content',
    },
  },
  ICON: {
    MAIN: 'ac-icon',
    CHECK: 'ac-icon--check',
  },
  DIVIDER: {
    MAIN: 'ac-step__divider',
    CONNECTED: 'ac-step__divider--connected',
  },
};

// Controller
class AcStepController extends React.Component {
  constructor(props) {
    super(props);

    this.onStepClickEvent = this.onStepClickEvent.bind(this);
    this.getCircleSize = this.getCircleSize.bind(this);
    this.renderDivider = this.renderDivider.bind(this);

    const circleSize = this.getCircleSize();

    this.state = {
      active: props.current,
      circleSize: circleSize,
      circleViewBox: `0 0 ${circleSize + 2} ${circleSize + 2}`,
      circleRadius: circleSize / 2,
    };
  }

  onStepClickEvent(event) {
    event.preventDefault();

    const { callback, id } = this.props;
    if (callback) callback(event, id);
  }

  getCircleSize() {
    const { size } = this.props;

    let circleSize = size === 'small' ? 26 : 40;

    return circleSize;
  }

  renderDivider() {
    const { number, total } = this.props;
    return number >= 0 && number - 1 < total - 1;
  }

  getDasharrayValue() {
    const { circleRadius } = this.state;
    const { finished, current } = this.props;
    const targetPercentage = finished || current ? 100 : 0;
    const roundCircum = 2 * circleRadius * Math.PI;
    const roundDraw = (targetPercentage * roundCircum) / 100;

    return `${roundDraw} 999`;
  }

  getSVGInlineStyles() {
    const { circleSize } = this.state;
    return {
      width: `${circleSize / 10}rem`,
      height: `${circleSize / 10}rem`,
    };
  }

  getIconClassNames() {
    const { icon } = this.props;
    return clsx(_CLASSES.ICON.MAIN, icon && _CLASSES.ICON[icon.toUpperCase()]);
  }

  getSVGContentClassNames() {
    return clsx(_CLASSES.SVG.CONTENT.MAIN);
  }

  getSVGCircleClassNames() {
    return clsx(_CLASSES.SVG.CIRCLE.MAIN);
  }

  getSVGCircleFrontClassNames() {
    return clsx(_CLASSES.SVG.CIRCLE.FRONT);
  }

  getSVGCircleFillClassNames() {
    return clsx(_CLASSES.SVG.CIRCLE.FILL);
  }

  getSVGCircleBackClassNames() {
    return clsx(_CLASSES.SVG.CIRCLE.BACK);
  }

  getSVGClassNames() {
    return clsx(_CLASSES.SVG.MAIN);
  }

  getDividerClassNames() {
    const { connected } = this.props;
    return clsx(_CLASSES.DIVIDER.MAIN, connected && _CLASSES.DIVIDER.CONNECTED);
  }

  getSubTitleClassNames() {
    return clsx(_CLASSES.TITLE.MAIN, _CLASSES.TITLE.SUB);
  }

  getTitleClassNames() {
    return clsx(_CLASSES.TITLE.MAIN);
  }

  getContentClassNames() {
    return clsx(_CLASSES.CONTENT);
  }

  getStyleClassNames() {
    const { horizontal, current, finished, type, size } = this.props;

    return clsx(
      _CLASSES.MAIN,
      horizontal && _CLASSES.HORIZONTAL,
      type && `${_CLASSES.MAIN}__${type}`,
      size && _CLASSES.SIZES[size.toUpperCase()],
      current && !finished && _CLASSES.CURRENT,
      finished && _CLASSES.FINISHED
    );
  }
}

AcStepController.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  comment: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  indexes: PropTypes.bool,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  total: PropTypes.number,
  horizontal: PropTypes.bool,
  current: PropTypes.bool,
  finished: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.oneOf(['default', 'medium', 'small']),
  icon: PropTypes.oneOf(['check']),
  callback: PropTypes.func,
};

AcStepController.defaultProps = {
  title: '',
  subtitle: false,
  comment: false,
  horizontal: false,
  current: false,
  finished: false,
  indexes: false,
  icon: 'check',
};

export default AcStepController;
