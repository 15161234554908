// Imports => React
import React from 'react';

// Imports => Controller
import AcHamburgerController from './ac-hamburger.controller';

// Component
class AcHamburger extends AcHamburgerController {
  render() {
    return (
      <div className={this.getStyleClassNames()} onClick={this.handleCallback}>
        <span className={this.getLayerClassNames(1)} />
        <span className={this.getLayerClassNames(2)} />
        <span className={this.getLayerClassNames(3)} />
      </div>
    );
  }
}

export default AcHamburger;
