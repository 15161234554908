// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-hamburger',
  OPEN: 'ac-hamburger--open',
  LAYER: {
    MAIN: 'ac-hamburger__layer',
    PREFIX: 'ac-hamburger__layer--',
  },
};

// Controller
class AcHamburgerController extends React.Component {
  constructor(props) {
    super(props);

    this.handleCallback = this.handleCallback.bind(this);
  }

  handleCallback() {
    const { callback } = this.props;

    if (callback) callback();
  }

  getLayerClassNames(n) {
    return clsx(_CLASSES.LAYER.MAIN, `${_CLASSES.LAYER.PREFIX}${n}`);
  }

  getStyleClassNames() {
    const { open, className } = this.props;
    return clsx(_CLASSES.MAIN, open && _CLASSES.OPEN, className);
  }
}

AcHamburgerController.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

AcHamburgerController.defaultProps = {
  open: false,
};

export default AcHamburgerController;
