// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';

// Imports => Controller
import AcLanguageSelectorController from './ac-language-selector.controller';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

// Component
@inject('store')
@observer
class AcLanguageSelector extends AcLanguageSelectorController {
  buildLanguagesList() {
    const list = this.parseLanguagesList();

    return list.map(language => {
      return (
        <li
          className={this.getLanguagesListItemClassNames()}
          key={`key-${language}`}
          onClick={event => this.setLanguage(event, language)}
        >
          {language}
          <AcRipple theme={'dark'} size={'small'} fixed={true} />
        </li>
      );
    }, this);
  }

  render() {
    const { currentLanguage } = this.state;

    return (
      <div
        ref={node => (this.wrp = node)}
        className={this.getStyleClassNames()}
        style={this.getInlineStyle()}
      >
        <div
          className={this.getSelectedLanguageClassNames()}
          onClick={this.toggleList}
        >
          {currentLanguage}
        </div>
        <ul className={this.getLanguagesListClassNames()}>
          {this.buildLanguagesList()}
        </ul>
      </div>
    );
  }
}

export default AcLanguageSelector;
