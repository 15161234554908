// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Imports => Utilities
import AcUUID from '@utils/ac-uuid.js';

const _CLASSES = {
  MAIN: 'ac-account-selector',
  LOADING: 'ac-account-selector--loading',
  DEFAULT: 'ac-account-selector--default',
  PITCH: 'ac-account-selector--pitch',
  SELECTED: 'ac-account-selector__selected',
  OPEN_MENU: 'ac-account-selector--open',
  LANGUAGES: {
    MAIN: 'ac-account-selector__accounts-list',
    ITEM: 'ac-account-selector__accounts-list__item',
  },
};

// Controller
class AcAccountSelectorController extends React.Component {
  constructor(props) {
    super(props);

    this.wrp = null;

    this.store = props.store;

    this.state = {
      preferredAccount: props.preferredAccount,
      openList: false,
    };

    this.buildAccountsList = this.buildAccountsList.bind(this);
    this.parseAccountList = this.parseAccountList.bind(this);
    this.handleClickEvent = this.handleClickEvent.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.setAccount = this.setAccount.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickEvent, false);
  }

  componentWillUnMount() {
    document.removeEventListener('click', this.handleClickEvent, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.preferredAccount !== null &&
      prevState.preferredAccount !== null &&
      nextProps.preferredAccount.id === prevState.preferredAccount.id
    )
      return prevState;

    return {
      ...prevState,
      preferredAccount: nextProps.preferredAccount,
    };
  }

  handleClickEvent(event) {
    const { loading } = this.store.profile;

    if (loading) return;
    if (!event) return;
    if (!this.wrp || this.wrp === null) return;
    if (this.wrp.contains(event.target)) return;

    this.toggleList(false);
  }

  toggleList(state) {
    const { openList } = this.state;

    this.setState({
      openList: typeof state === 'boolean' ? state : !openList,
    });
  }

  parseAccountList() {
    const { preferredAccount } = this.state;
    const { accounts } = this.props;

    let accountsList = accounts.slice(0).filter(account => {
      return preferredAccount === null || account.id !== preferredAccount.id;
    });

    // Add one blanco 'All accounts' to the beginning of the array
    accountsList.unshift({
      id: null,
      name: 'Alle accounts',
      parent_id: null,
      type: null,
    });

    return accountsList;
  }

  setAccount(event, account) {
    event.preventDefault();

    this.toggleList(false);

    const { callback } = this.props;
    if (callback) callback(account);
  }

  getSelectedAccountClassNames() {
    return clsx(_CLASSES.SELECTED);
  }

  getAccountListItemClassNames() {
    return clsx(_CLASSES.LANGUAGES.ITEM);
  }

  getAccountListClassNames() {
    return clsx(_CLASSES.LANGUAGES.MAIN);
  }

  getStyleClassNames() {
    const { openList } = this.state;
    const { theme, className } = this.props;
    const { loading } = this.store.profile;

    return clsx(
      _CLASSES.MAIN,
      openList && _CLASSES.OPEN_MENU,
      theme && _CLASSES[theme.toUpperCase()],
      loading && _CLASSES.LOADING,
      className
    );
  }

  getInlineStyle() {
    const { styles } = this.props;

    return {
      ...styles,
    };
  }
}

AcAccountSelectorController.propTypes = {
  callback: PropTypes.func.isRequired,
  preferredAccount: PropTypes.object,
  accounts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'transparent', 'pitch']),
  styles: PropTypes.object,
};

AcAccountSelectorController.defaultProps = {
  styles: {},
  theme: 'default',
  defID: AcUUID(),
};

export default AcAccountSelectorController;
