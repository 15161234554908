// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-attribute',
  HORIZONTAL: 'ac-attribute--horizontal',
  LABEL: 'ac-attribute__label',
  VALUE: 'ac-attribute__value',
};

const AcAttribute = ({ attribute, t }) => {
  const getValueClassNames = () => {
    return clsx(_CLASSES.VALUE);
  };

  const getLabelClassNames = () => {
    return clsx(_CLASSES.LABEL);
  };

  const getStyleClassNames = () => {
    return clsx(_CLASSES.MAIN, attribute.horizontal && _CLASSES.HORIZONTAL);
  };

  return attribute &&
    attribute.label !== undefined &&
    attribute.label === 'Locatie' &&
    attribute.locations ? (
    <div className={getStyleClassNames()}>
      <div className={getLabelClassNames()}>{t(attribute.label)}</div>
      <div className={getValueClassNames()}>
        {attribute.shows[0] ? (
          <a
            className={getValueClassNames()}
            href={attribute.values[0]}
            target="_blank"
            rel="noreferrer"
          >
            {attribute && attribute.locations[0] && attribute.locations[0]}
          </a>
        ) : (
          <span>{attribute.locations[0]}</span>
        )}
      </div>

      <div className={getValueClassNames()}>
        {attribute.shows[1] ? (
          <a
            className={getValueClassNames()}
            href={attribute.values[1]}
            target="_blank"
            rel="noreferrer"
          >
            {attribute && attribute.locations[1] && attribute.locations[1]}
          </a>
        ) : (
          <span>{attribute.locations[1]}</span>
        )}
      </div>

      <div className={getValueClassNames()}>
        {attribute.shows[2] ? (
          <a
            className={getValueClassNames()}
            href={attribute.values[2]}
            target="_blank"
            rel="noreferrer"
          >
            {attribute && attribute.locations[2] && attribute.locations[2]}
          </a>
        ) : (
          <span>{attribute.locations[2]}</span>
        )}
      </div>

      <div className={getValueClassNames()}>
        {attribute.shows[3] ? (
          <a
            className={getValueClassNames()}
            href={attribute.values[3]}
            target="_blank"
            rel="noreferrer"
          >
            {attribute && attribute.locations[3] && attribute.locations[3]}
          </a>
        ) : (
          <span>{attribute.locations[3]}</span>
        )}
      </div>

      <div className={getValueClassNames()}>
        {attribute.shows[4] ? (
          <a
            className={getValueClassNames()}
            href={attribute.values[4]}
            target="_blank"
            rel="noreferrer"
          >
            {attribute && attribute.locations[4] && attribute.locations[4]}
          </a>
        ) : (
          <span>{attribute.locations[4]}</span>
        )}
      </div>

      <div className={getValueClassNames()}>
        {attribute.shows[5] ? (
          <a
            className={getValueClassNames()}
            href={attribute.values[5]}
            target="_blank"
            rel="noreferrer"
          >
            {attribute && attribute.locations[5] && attribute.locations[5]}
          </a>
        ) : (
          <span>{attribute.locations[5]}</span>
        )}
      </div>
    </div>
  ) : (
    attribute.label && attribute.label !== 'Locatie' && (
      <div className={getStyleClassNames()}>
        <div className={getLabelClassNames()}>{t(attribute.label)}</div>
        <div className={getValueClassNames()}>
          {attribute.value && attribute.value !== '' && attribute.value}
        </div>
      </div>
    )
  );
};

export default withNamespaces()(React.memo(AcAttribute));
