// Imports => React
import React, { useCallback } from 'react';
import clsx from 'clsx';

// Imports => Atoms
import AcCard from '@atoms/ac-card/ac-card.web';

const _CLASSES = {
  MAIN: 'ac-document',
  LABEL: 'ac-document__label',
  DATE: 'ac-document__date',
  ICON: {
    MAIN: 'ac-icon ac-document__icon',
    DOC: 'ac-icon--file-word-box',
    DOCX: 'ac-icon--file-word-box',
    PDF: 'ac-icon--file-pdf-box',
    POWERPOINT: 'ac-icon--file-powerpoint-box',
    XLS: 'ac-icon--file-excel-box',
  },
};

const AcDocument = ({
  id,
  label,
  created,
  type,
  mimetype,
  size,
  url,
  name,
  callback = null,
}) => {
  const handleClick = useCallback(
    (event) => {
      if (event && event.preventDefault) event.preventDefault();
      if (event && event.stopPropagation) event.stopPropagation();

      if (callback) callback(url, name, id, mimetype);
      else if (url) window.open(url, name);
    },
    [callback, url, name, id]
  );

  const getDocumentLabelClassNames = useCallback(() => {
    return clsx(_CLASSES.LABEL);
  }, []);

  const getDocumentCreatedOnClassNames = useCallback(() => {
    return clsx(_CLASSES.DATE);
  }, []);

  const getDocumentIconClassNames = useCallback((type) => {
    let file_type = false;

    switch (type) {
      case 'application/pdf':
        file_type = 'pdf';
        break;

      case 'application/xls':
        file_type = 'xls';
        break;

      case 'application/doc':
      case 'application/docx':
        file_type = 'docx';
        break;

      case 'application/ppt':
        file_type = 'powerpoint';
        break;

      default:
        file_type = false;
    }

    return clsx(
      _CLASSES.ICON.MAIN,
      file_type && _CLASSES.ICON[file_type.toUpperCase()]
    );
  }, []);

  const getDocumentClassNames = useCallback(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  return (
    <AcCard
      width={size}
      height={size}
      className={getDocumentClassNames()}
      callback={handleClick}
    >
      <i className={getDocumentIconClassNames(type)} />
      <div className={getDocumentLabelClassNames()}>{label}</div>
      {created && <div className={getDocumentCreatedOnClassNames()}>{created}</div>}
    </AcCard>
  );
};

export default React.memo(AcDocument);
