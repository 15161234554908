// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import { Fade } from 'react-reveal';

// Imports => Controller
import AcAccountSelectorController from './ac-account-selector.controller';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

// Component
@inject('store')
@observer
class AcAccountSelector extends AcAccountSelectorController {
  buildAccountsList() {
    const { t } = this.props;
    const list = this.parseAccountList();

    return list.map(account => {
      return (
        <li
          className={this.getAccountListItemClassNames()}
          key={`key-${account.id}`}
          onClick={event => this.setAccount(event, account)}
        >
          {t(account === null ? 'Alle accounts' : account.name)}
          <AcRipple theme={'dark'} size={'small'} fixed={true} />
        </li>
      );
    }, this);
  }

  render() {
    const { defID, preferredAccount } = this.state;
    const { t } = this.props;

    return (
      <div
        ref={node => (this.wrp = node)}
        className={this.getStyleClassNames()}
        style={this.getInlineStyle()}
      >
        <div
          className={this.getSelectedAccountClassNames()}
          onClick={this.toggleList}
        >
          <span className={'ac-account-selector__label'}>{t('Account')}</span>
          <Fade key={preferredAccount === null ? defID : preferredAccount.id}>
            {t(
              preferredAccount === null
                ? 'Alle accounts'
                : preferredAccount.name
            )}
          </Fade>
        </div>
        <ul className={this.getAccountListClassNames()}>
          {this.buildAccountsList()}
        </ul>
      </div>
    );
  }
}

export default withNamespaces()(AcAccountSelector);
