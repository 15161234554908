// Imports => React
import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-button',
  SIZES: {
    DEFAULT: 'ac-button--regular',
    SMALL: 'ac-button--small',
  },
  THEME: {
    DEFAULT: 'ac-button--pitch',
    OUTLINE: 'ac-button--outline',
    PITCH: {
      MAIN: 'ac-button--pitch',
      DEFAULT: '',
      OUTLINE: 'ac-button--pitch-outline',
      TRANSPARENT: 'ac-button--pitch-transparent',
      FORGOT: 'ac-button--alpha-forgot',
    },
    ALPHA: {
      MAIN: 'ac-button--alpha',
      DEFAULT: '',
      OUTLINE: 'ac-button--alpha-outline',
      OUTLINEFILLED: 'ac-button--alpha-outline--filled',
      TRANSPARENT: 'ac-button--alpha-transparent',
      FORGOT: 'ac-button--alpha-forgot',
    },
    ALPHALIGHT: {
      MAIN: 'ac-button--alpha-light',
      DEFAULT: '',
      OUTLINE: 'ac-button--alpha-light-outline',
      TRANSPARENT: 'ac-button--alpha-light-transparent',
      FORGOT: 'ac-button--alpha-forgot',
    },
    DOWNLOAD: {
      MAIN: 'ac-button--download',
    },
    WHITE: {
      DEFAULT: '',
      OUTLINE: 'ac-button--white-outline',
    },
  },
  DISABLED: 'ac-button--disabled',
};

// Component
const AcButton = ({
  theme = 'default',
  disabled = false,
  size = 'default',
  type = 'button',
  variant,
  className,
  callback,
  children,
  ...rest
}) => {
  const $el = React.createRef();

  const handleClick = useCallback(event => {
    if (!disabled && callback) callback(event, $el);
  }, []);

  const getThemeAndStyleClassNames = useCallback((theme, variant) => {
    let className = false;

    const match = {
      theme: theme
        ? theme
            .replace('-', '')
            .trim()
            .toUpperCase()
        : false,
      variant: variant
        ? variant
            .replace('-', '')
            .trim()
            .toUpperCase()
        : false,
    };

    if (match.theme && match.variant) {
      className = _CLASSES.THEME[match.theme][match.variant];
    } else if (match.theme) {
      className = _CLASSES.THEME[match.theme].MAIN;
    } else if (match.variant) {
      className = _CLASSES.THEME.OUTLINE;
    }

    return className;
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx(
      _CLASSES.MAIN,
      getThemeAndStyleClassNames(theme, variant),
      size && _CLASSES.SIZES[size.toUpperCase()],
      disabled && _CLASSES.DISABLED,
      className
    );
  }, [disabled]);

  return (
    <button
      {...rest}
      ref={$el}
      aria-roledescription={'button'}
      className={getStyleClassNames}
      onClick={handleClick}
      disabled={disabled}
      type={type}
    >
      {children || 'Button'}
    </button>
  );
};

export default React.memo(AcButton);
