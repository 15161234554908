// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcKPIController from './ac-kpi.controller.js';

class AcKPI extends AcKPIController {
  renderValue() {
    const { label, type } = this.props;
    const { value } = this.state;

    const isObject =
      Object.prototype.toString.call(value).split(/\W/)[2] === 'Object';

    if (isObject) {
      if (typeof value.USD === 'undefined') {
        value.USD = 0;
      } else if (typeof value.EUR === 'undefined') {
        value.EUR = 0;
      }
    }

    if (value && type !== 'date' && isObject) {
      return Object.keys(value)
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        .map(key => {
          const item = value[key];
          return (
            <p
              key={`${label}-${key}`}
              className={this.getDetailsValueClassNames()}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: this.handleFormatting(item, key),
                }}
              />
            </p>
          );
        });
    } else {
      return (
        <p className={this.getDetailsValueClassNames()}>
          <span
            dangerouslySetInnerHTML={{
              __html: this.handleFormatting(value),
            }}
          />
        </p>
      );
    }
  }

  render() {
    const { comparisons, label, t } = this.props;

    return (
      <div className={this.getStyleClassNames()}>
        <div className={'ac-kpi__body'}>
          <div className={this.getIconWrpClassNames()}>
            <div className={this.getIconClassNames()}>{this.getIcon()}</div>
          </div>

          <div className={this.getDetailsWrpClassNames()}>
            <p className={this.getDetailsLabelClassNames()}>{t(label)}</p>

            {this.renderValue()}
          </div>
        </div>

        {comparisons && (
          <div className={this.getComparisonsWrpClassNames()}>
            {comparisons
              .filter(item => item.value && item.value !== null)
              .map(item => (
                <div
                  className={this.getComparisonClassNames()}
                  key={`kpi-${item.label}`}
                >
                  <div
                    className={this.getComparisonsValueClassNames(item.sign)}
                  >
                    {item.value}
                  </div>
                  <div className={this.getComparisonsLabelClassNames()}>
                    {t('t.o.v')} {item.label}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

export default withNamespaces()(AcKPI);
