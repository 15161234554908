// Imports => React
import React, { useMemo, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import Blazy from 'blazy';

// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

const _CLASSES = {
  MAIN: 'ac-image',
  WRP: 'ac-image-wrp',
  LOADED: 'ac-image-wrp--loaded',
  BLAZY: 'b-lazy',
  IMAGE: 'ac-image--image',
  BACKGROUND: 'ac-image--background-image',
};

const AcImage = React.forwardRef(
  (
    {
      id = `ac-image-${AcUUID()}`,
      placeholder = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
      source,
      srcset = false,
      type = 'background',
      alt = '',
      size = {
        width: 'auto',
        height: 'auto',
      },
      callback,
      wrpClassName,
      className,
      parallax = false,
      ...rest
    },
    ref
  ) => {
    const [error, setError] = useState(false);

    const $wrp = useRef(ref);
    const $element = useRef(null);

    let timer = null;
    let delay = 50;
    let blazy = null;

    useEffect(() => {
      init();
      return () => {
        clearTimeout(timer);
      };
    }, [id, source, $wrp, $element]);

    const init = () => {
      return new Promise(resolve => {
        if (timer) clearTimeout(timer);
        if (!error && source) {
          timer = setTimeout(() => {
            if (blazy === null) {
              blazy = new Blazy(getBlazyConfig);
            } else {
              blazy.revalidate();
            }
            delay = 10;

            resolve();
          }, delay);
        }
      });
    };

    const getBlazyConfig = useMemo(() => {
      return {
        selector: `#${id}`,
        container: '#ac-scroller',
        offset: 10,
        loadInvisible: true,
        success: elem => {
          if ($element !== null && $element.current !== null) {
            if (callback) callback(true);
          }
        },
        error: elem => {
          if ($element !== null && $element.current !== null) {
            setError(true);
            if (callback) callback(false);
          }
        },
      };
    }, [id, callback, source]);

    const getStyleClassNames = useMemo(() => {
      return clsx([_CLASSES.BLAZY, _CLASSES.MAIN, className]);
    }, []);

    const getBackgroundStyleClassNames = useMemo(() => {
      return clsx([
        _CLASSES.BLAZY,
        _CLASSES.MAIN,
        _CLASSES.BACKGROUND,
        className,
      ]);
    }, []);

    const getWrapperClassNames = useMemo(() => {
      return clsx([
        _CLASSES.WRP,
        wrpClassName,
        _CLASSES.LOADED,
        type === 'image' && _CLASSES.IMAGE,
      ]);
    }, [type]);

    const renderImage = () => {
      return (
        <img
          src={placeholder}
          data-src={source}
          className={getStyleClassNames}
          alt={alt}
          ref={$element}
          width={size.width}
          height={size.height}
          id={id}
          key={id}
          {...rest}
        />
      );
    };

    const renderBackgroundImage = () => {
      return (
        <div
          data-src={source}
          className={getBackgroundStyleClassNames}
          ref={$element}
          id={id}
          key={id}
          {...rest}
        />
      );
    };

    return (
      <div className={getWrapperClassNames} ref={$wrp}>
        {type === 'background' && renderBackgroundImage()}
        {type === 'image' && renderImage()}
      </div>
    );
  }
);

export default AcImage;
