// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/* eslint react-app/import/no-webpack-loader-syntax: off */
import AcLogoSVGDefault from '-!svg-react-loader!@assets/images/realiance-logo-white-1x.svg';
import AcLogoSVGPitch from '-!svg-react-loader!@assets/images/realiance-logo-1x.svg';

const _CLASSES = {
  MAIN: 'ac-logo',
  LIGHT: 'ac-logo--light',
  DARK: 'ac-logo--dark',
  ALPHA: 'ac-logo--alpha',
  CONTAINER: {
    MAIN: 'ac-logo__container',
  },
  VISUAL: {
    MAIN: 'ac-logo__visual',
  },
};

const _VISUAL = {
  DEFAULT: React.memo(AcLogoSVGDefault),
  PITCH: React.memo(AcLogoSVGPitch),
};

class AcLogoController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Visual: _VISUAL[props.theme.toUpperCase()],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.theme === prevState.theme) return prevState;
    return {
      ...prevState,
      Visual: _VISUAL[nextProps.theme.toUpperCase()],
    };
  }

  handleClickEvent() {
    this.props.routing.push('/');
  }

  getVisualClassNames() {
    return clsx(_CLASSES.VISUAL.MAIN);
  }

  getContainerClassNames() {
    return clsx(_CLASSES.CONTAINER.MAIN);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcLogoController.propTypes = {
  theme: PropTypes.oneOf(['default', 'pitch']),
};

AcLogoController.defaultProps = {
  theme: 'default',
};

export default AcLogoController;
