// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Imports => Utils
import AcCurrencySymbol from '@utils/ac-currency-symbol';
import AcFormatDate from '@utils/ac-format-date.js';
import AcFormatNumber from '@utils/ac-format-number.js';

// Imports => Constants
import KPIS from '@constants/project.kpis.constants.js';

const _CLASSES = {
  MAIN: 'ac-kpi',
  MULTIPLE: 'ac-kpi--multiple-values',
  SIZES: {
    DEFAULT: 'ac-kpi--default',
    SMALL: 'ac-kpi--small',
  },
  ICON: {
    MAIN: 'ac-kpi__icon',
    WRP: 'ac-kpi__icon-wrp',
  },
  DETAILS: {
    MAIN: 'ac-kpi__details',
    WRP: 'ac-kpi__details-wrp',
    LABEL: 'ac-kpi__details__label',
    VALUE: 'ac-kpi__details__value',
  },
  COMPARISONS: {
    WRP: 'ac-kpi__comparison-wrp',
    ITEM: 'ac-kpi__comparison__item',
    LABEL: 'ac-kpi__comparison__label',
    VALUE: {
      MAIN: 'ac-kpi__comparison__value',
      NEUTRAL: 'ac-kpi__comparison__value--neutral',
      POSITIVE: 'ac-kpi__comparison__value--positive',
      NEGATIVE: 'ac-kpi__comparison__value--negative',
    },
  },
};

class AcKPIController extends React.Component {
  constructor(props) {
    super(props);

    let value = props.value;

    if (
      props.type === 'number' ||
      props.type === 'percentage' ||
      props.type === 'currency'
    ) {
      if (Object.prototype.toString.call(value).split(/\W/)[2] !== 'Object') {
        value = parseFloat(props.value);
      }
    }

    this.state = {
      type: props.type || 'string',
      value,
    };

    this.getSuffix = this.getSuffix.bind(this);
    this.handleFormatting = this.handleFormatting.bind(this);
    this.getStyleClassNames = this.getStyleClassNames.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value === prevState.value) return prevState;

    return {
      ...prevState,
      value:
        prevState.type === 'number' ||
        prevState.type === 'percentage' ||
        prevState.type === 'currency'
          ? parseFloat(nextProps.value)
          : nextProps.value,
      start:
        prevState.type === 'number' ||
        prevState.type === 'percentage' ||
        prevState.type === 'currency'
          ? parseFloat(prevState.end)
          : prevState.end,
      end:
        prevState.type === 'number' ||
        prevState.type === 'percentage' ||
        prevState.type === 'currency'
          ? parseFloat(nextProps.value)
          : nextProps.value,
    };
  }

  handleFormatting = (value, _currency = null) => {
    const { type, label, currency } = this.props;
    const cur = _currency || currency;

    let result = '';
    let _value = value;

    switch (type) {
      case 'percentage':
        _value = value === null || isNaN(value) ? 0 : value;
        _value = Math.round(_value * 10) / 10;
        result = `${parseFloat(_value.toString())}%`;
        if (result.indexOf('.') > -1) result = result.replace('.', ',');
        break;

      case 'number':
        _value = value === null || isNaN(value) ? 0 : value;
        result = AcFormatNumber(_value, { precision: 0, unit: true }) || _value;
        if (_currency)
          result += ` ${
            _currency === 'USD' ? 'US' : _currency === 'EUR' ? 'EU' : ''
          }`;
        break;

      case 'currency':
        _value = value === null || isNaN(value) ? 0 : value;
        const symbol = _currency ? AcCurrencySymbol(_currency) : cur ? cur : '';
        const amount = Math.round(_value).toLocaleString('nl-NL', {
          style: 'decimal',
          minimumFractionDigits: 0,
        });
        result = `<em>${symbol}</em> <u>${amount}</u>`;
        break;

      case 'date':
        if (value) {
          result = AcFormatDate(value, undefined, 'DD MMMM YYYY');
        } else {
          result = '-';
        }
        break;

      default:
        result = value !== null && value !== '' ? value : '-';
        break;
    }

    if (
      label &&
      label === 'Totaalrendement' &&
      _currency &&
      _currency === 'USD'
    )
      result += ' (bruto)';
    else if (
      label &&
      label === 'Totaalrendement' &&
      _currency &&
      _currency === 'EUR'
    )
      result += ' (netto)';

    // return the value as a string to prevent shit breaking due to NaN or empty values from SalesForce...
    return `${result}`;
  };

  getSuffix = () => {
    const { type } = this.props;

    let suffix = '';

    if (type === 'percentage') {
      suffix = '%';
    }

    return suffix;
  };

  getIcon = () => {
    const { icon } = this.props;

    const Tag = KPIS[`${icon}`.toUpperCase()] || KPIS[1];

    return <Tag />;
  };

  getComparisonsValueClassNames = sign => {
    return clsx(
      _CLASSES.COMPARISONS.VALUE.MAIN,
      sign && _CLASSES.COMPARISONS.VALUE[sign.toUpperCase()]
    );
  };

  getComparisonsLabelClassNames = () => {
    return clsx(_CLASSES.COMPARISONS.LABEL);
  };

  getComparisonClassNames = () => {
    return clsx(_CLASSES.COMPARISONS.ITEM);
  };

  getComparisonsWrpClassNames = () => {
    return clsx(_CLASSES.COMPARISONS.WRP);
  };

  getDetailsValueClassNames = () => {
    return clsx(_CLASSES.DETAILS.VALUE);
  };

  getDetailsLabelClassNames = () => {
    return clsx(_CLASSES.DETAILS.LABEL);
  };

  getDetailsWrpClassNames = () => {
    return clsx(_CLASSES.DETAILS.WRP);
  };

  getIconClassNames = () => {
    return clsx(_CLASSES.ICON.MAIN);
  };

  getIconWrpClassNames = () => {
    return clsx(_CLASSES.ICON.WRP);
  };

  getStyleClassNames = () => {
    const { type, size, className } = this.props;
    const { value } = this.state;

    let hasMultipleValues = false;

    if (
      value &&
      type !== 'date' &&
      Object.prototype.toString.call(value).split(/\W/)[2] === 'Object'
    )
      hasMultipleValues = true;

    return clsx(
      _CLASSES.MAIN,
      size && _CLASSES.SIZES[size.toUpperCase()],
      hasMultipleValues && _CLASSES.MULTIPLE,
      className
    );
  };
}

AcKPIController.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  label: PropTypes.string,
  currency: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  size: PropTypes.oneOf(['default', 'small']),
  type: PropTypes.oneOf(['number', 'currency', 'percentage', 'date', 'string']),
  duration: PropTypes.number, // in seconds
  delay: PropTypes.number, // in seconds
};

AcKPIController.defaultProps = {
  currency: '€',
  label: 'label',
  value: 'value',
  icon: 'default',
  size: 'default',
  type: 'string',
  duration: 2,
  delay: 0.5,
};

export default AcKPIController;
